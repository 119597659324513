import React, { useState } from "react";
import { motion } from "framer-motion";
import { slideDown } from "../lib/variants";
import { Link } from "gatsby";
import { HiOutlineMenu, HiOutlineX } from "react-icons/hi"; // Hamburger and Close icons
import { StaticImage } from "gatsby-plugin-image";
const Navbar = () => {
  const [visible, setVisible] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleMouseEnter = () => setVisible(true);
  const handleMouseLeave = () => setVisible(false);
  const toggleMobileMenu = () => setMobileMenuOpen(!mobileMenuOpen);

  return (
    <>
      {/* Desktop Navbar */}
      <div className="container hidden lg:flex justify-around h-[10vh] items-center relative z-50">
        <motion.div
          variants={slideDown(0.1)}
          initial="initial"
          animate="animate"
        >
          <StaticImage
            src="../images/robocap_logo.webp"
            alt="robocap-logo"
            loading="eager"
          />
        </motion.div>
        <motion.div
          variants={slideDown(0.1)}
          initial="initial"
          animate="animate"
        >
          <ul className="flex gap-10 text-md montserrat-custom relative z-50">
            <Link
              to="/"
              className={`cursor-pointer ${
                location.pathname === "/" ? "text-blue-500" : ""
              }`}
            >
              Home
            </Link>
            <Link
              to="/about"
              className={`cursor-pointer ${
                location.pathname === "/about/" ? "text-blue-500" : ""
              }`}
            >
              About us
            </Link>
            {/* <Link
              to="/testimonials/"
              className={`cursor-pointer ${
                location.pathname === "/testimonials/" ? "text-blue-500" : ""
              }`}
            >
              Testimonials
            </Link> */}
            <div
              className={`relative z-50`}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {/* <span>
                <span
                  className={`cursor-pointer ${
                    location.pathname.startsWith("/investing") ||
                    location.pathname.startsWith("/property-investments")
                      ? "text-blue-500"
                      : ""
                  }`}
                >
                  Investing
                </span>
                {visible && (
                  <motion.ul
                    className="absolute bg-gray-100 shadow-md p-4 py-2 rounded-lg z-50"
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Link
                      to="/investing/"
                      className={`cursor-pointer ${
                        location.pathname === "/investing/"
                          ? "text-blue-500"
                          : ""
                      }`}
                    >
                      <li className="py-1">Investments</li>
                    </Link>
                    <Link
                      to="/property-investments/"
                      className={`cursor-pointer ${
                        location.pathname === "/property-investments/"
                          ? "text-blue-500"
                          : ""
                      }`}
                    >
                      <li className="py-1">Property Solutions</li>
                    </Link>
                  </motion.ul>
                )}
              </span> */}
              <Link
                to="/contact-us"
                className={`cursor-pointer ${
                  location.pathname === "/contact-us/" ? "text-blue-500" : ""
                }`}
              >
                Contact Us
              </Link>
            </div>
          </ul>
        </motion.div>
      </div>

      {/* Mobile Navbar */}
      <div className="lg:hidden flex justify-between items-center p-4 bg-white shadow-md z-50">
        <StaticImage
          src="../images/robocap_logo.webp"
          alt="robocap-logo"
          className="h-8"
          loading="eager"
        />
        <button
          onClick={toggleMobileMenu}
          className="text-3xl focus:outline-none z-50" // Ensure z-index is above the mobile menu
          aria-label="Toggle mobile menu"
        >
          {mobileMenuOpen ? <HiOutlineX /> : <HiOutlineMenu />}
        </button>
      </div>

      {/* Mobile Menu */}
      {mobileMenuOpen && (
        <motion.div
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 50 }}
          transition={{ duration: 0.3 }}
          className="lg:hidden absolute top-0 left-0 w-full bg-white shadow-md z-40 p-6"
        >
          <ul className="flex flex-col gap-6 text-lg">
            <Link
              to="/"
              className={`cursor-pointer ${
                location.pathname === "/" ? "text-blue-500" : ""
              }`}
            >
              Home
            </Link>
            <Link
              to="/about"
              className={`cursor-pointer ${
                location.pathname === "/about/" ? "text-blue-500" : ""
              }`}
            >
              About us
            </Link>
            {/* <Link
              to="/testimonials/"
              className={`cursor-pointer ${
                location.pathname === "/testimonials/" ? "text-blue-500" : ""
              }`}
            >
              Testimonials
            </Link> */}
            {/* <Link
              to="/investing/"
              className={`cursor-pointer ${
                location.pathname === "/investing/" ? "text-blue-500" : ""
              }`}
            >
              Investing
            </Link> */}
            {/* <Link
              to="/property-investments/"
              className={`cursor-pointer ${
                location.pathname === "/property-investments/"
                  ? "text-blue-500"
                  : ""
              }`}
            >
              Property Solutions
            </Link> */}
            <Link
              to="/contact-us"
              className={`cursor-pointer ${
                location.pathname === "/contact-us/" ? "text-blue-500" : ""
              }`}
            >
              Contact Us
            </Link>
          </ul>
        </motion.div>
      )}
    </>
  );
};

export default Navbar;
