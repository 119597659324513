import { Link } from "gatsby";
import React from "react";

const Footer = () => {
  const nav = [
    { id: 1, name: "Home", to: "/" },
    { id: 2, name: "About Us", to: "/about/" },
    { id: 3, name: "Careers", to: "/careers/" },
    { id: 4, name: "Terms of Service", to: "/terms-conditions/" },
    { id: 5, name: "Privacy Policy", to: "/privacy-policy/" },
  ];
  return (
    <footer className="bg-blue-900 text-white py-10 px-5">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-4 gap-8">
        <div className="mb-8 md:mb-0">
          <h2 className="text-3xl font-bold mb-3 font-bebas">RoboCap</h2>
          <p className="mt-4 font-light font-montserrat">
            RoboCap is a boutique financial services firm based in Mumbai,
            India. We believe that bespoke services are core to our philosophy
            as every individual has unique financial goals.
          </p>
        </div>

        <div className="mb-8 md:mb-0">
          <h3 className="text-xl font-semibold border-b-2 border-gray-600 pb-2 mb-3 font-bebas">
            Useful Links
          </h3>
          <ul className="mt-4 space-y-2 font-montserrat">
            {nav.map((link) => (
              <li key={link.id}>
                <Link
                  to={link.to}
                  className="hover:underline hover:text-gray-300 transition duration-300"
                >
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        {/* Contact Information */}
        <div className="mb-8 md:mb-0">
          <h3 className="text-xl font-semibold border-b-2 border-gray-600 pb-2 mb-3 font-bebas">
            Contact Us
          </h3>
          <p className="mt-4 font-light font-montserrat">
            S-168, B-WING 2 ND FLOOR EXPRESS ZONE MALL, OFF WE HIGHWAY OPP,
            ADANI ELECTRICITY GOREGAON MUMBAI 400063
          </p>
          <p className="mt-2 font-light font-montserrat">
            Phone: +91-90298 00731
          </p>
          <p className="mt-2 font-light font-montserrat">
            Email: support@robocap.co
          </p>

          <p className="mt-4 text-sm text-gray-400 font-montserrat">
            <span className="font-semibold text-white">
              BSE Registration Code:
            </span>{" "}
            AP01017901161176
          </p>
          <p className="text-sm text-gray-400 font-montserrat">
            <span className="font-semibold text-white">
              NSE Registration Code:
            </span>{" "}
            AP1493574273
          </p>
        </div>

        <div className="mb-8 md:mb-0">
          <h3 className="text-xl font-semibold border-b-2 border-gray-600 pb-2 mb-3 font-bebas">
            Request Callback
          </h3>
          <form className="mt-4 font-montserrat">
            <input
              type="text"
              placeholder="Enter Mobile No."
              className="w-full p-2 rounded border border-gray-400 focus:outline-none focus:border-blue-300 transition duration-300 mb-4"
            />
            <button
              type="submit"
              className="w-full bg-blue-700 hover:bg-blue-600 p-2 rounded transition duration-300"
            >
              Submit
            </button>
          </form>
        </div>
      </div>

      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center mt-10 border-t border-gray-600 pt-4">
        <p className="text-sm font-montserrat mb-4 md:mb-0">
          © 2024 RoboCap. All Rights Reserved.
        </p>
        <div className="flex space-x-4">
          {["facebook-f", "twitter", "youtube", "linkedin-in"].map((icon) => (
            <a
              href="#"
              className="hover:text-gray-400 transition duration-300"
              key={icon}
            >
              <i className={`fab fa-${icon}`}></i>
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
